import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(8),
  },
  icon: {
    flexShrink: 0,
  },
}));
