import { FC, PropsWithChildren } from 'react';
import { SeoContentLink } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';

import useStyles from './styles';

type LinkChunkProps = {
  links: SeoContentLink[];
  type: 'subject' | 'location' | 'level';
  isOnOneColumn?: boolean;
  className?: string;
};

export type SeoContainerLabelProps = LinkChunkProps;

const SeoContainerLabel: FC<PropsWithChildren<SeoContainerLabelProps>> = ({
  links,
  type,
  isOnOneColumn = false,
  className = '',
}) => {
  const { classes, cx } = useStyles({ isOnOneColumn });

  const handleClick = (title: string) => {
    switch (type) {
      case 'subject':
        window.dataLayer.push({
          subject: title,
          event: 'block_subjects',
        });
        break;
      case 'location':
        window.dataLayer.push({
          subject: title,
          event: 'block_cities',
        });
        break;
      case 'level':
        window.dataLayer.push({
          subject: title,
          event: 'block_levels',
        });
        break;
      default:
        break;
    }
  };

  return (
    <ul className={cx(classes.ul, className)}>
      {links.map(({ title, url }) => (
        <li key={title} className={classes.li}>
          <NextLinkWithNoCss href={url} onClick={() => handleClick(title)}>
            <Typography variant="bodyLgBold" component="span">
              {title}
            </Typography>
          </NextLinkWithNoCss>
        </li>
      ))}
    </ul>
  );
};

export default SeoContainerLabel;
