import { FC, PropsWithChildren } from 'react';
import useStyles from 'src/components/LandingMozza/common/Hero/Reason/styles';

import CheckWithCircle from '@/Atoms/Icons/CheckWithCircle';

const Reason: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <li className={classes.container}>
      <CheckWithCircle className={classes.icon} />
      {children}
    </li>
  );
};

export default Reason;
