import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(
  ({ palette: { supporting, common }, breakpoints }) => ({
    faq: {
      borderRadius: rem(16),
      background: `linear-gradient(180deg, ${supporting.amber[900]} 0%, ${common.white} 100%)`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: `${rem(20)} ${rem(16)}`,
      gap: rem(32),
      [breakpoints.up('md')]: {
        padding: rem(60),
        gap: rem(40),
      },
      [breakpoints.up('lg')]: {
        padding: rem(60),
        gap: rem(80),
      },
    },
    faqQuestionsAndAnswersList: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(16),
      width: '100%',
    },
    title: {
      fontWeight: 400,
    },
  })
);
