import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landing.mozza.faq.title',
    defaultMessage: `Les questions fréquemment posées<enter></enter><regular>par les futurs élèves 🔍</regular>`,
  },
  findTeacherButton: {
    id: 'landing.mozza.faq.find.button',
    defaultMessage: `Trouver un professeur`,
  },
});
