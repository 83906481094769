import { FC, ReactElement } from 'react';
import config from 'src/components/LandingMozza/common/LandingFaq/config';

import CheckUpCotillon from '@/Atoms/Icons/Cotillons/CheckUpCotillon';
import SquareBlueCotillon from '@/Atoms/Icons/Cotillons/SquareBlueCotillon';
import TornadoCotillon from '@/Atoms/Icons/Cotillons/TornadoCotillon';
import Cotillon from '@/components/LandingMozza/common/Cotillon/Cotillon';
import Faq from '@/components/LandingMozza/common/Faq/Faq';
import { QuestionAndAnswerType } from '@/components/LandingMozza/common/Faq/types';

type Props = {
  title?: string | ReactElement;
  qa?: QuestionAndAnswerType[];
  handleRedirection: () => void;
};

const LandingFaq: FC<Props> = ({ title, qa, handleRedirection }) => (
  <>
    <Cotillon top={-78} left={0}>
      <CheckUpCotillon />
    </Cotillon>
    <Cotillon top={36} right={30}>
      <TornadoCotillon />
    </Cotillon>
    <Cotillon bottom={60} left={30}>
      <SquareBlueCotillon />
    </Cotillon>
    <Faq
      title={title}
      questionsAndAnswers={qa ?? config}
      handleRedirection={handleRedirection}
    />
  </>
);

export default LandingFaq;
