import { FC, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

import { QuestionAndAnswerType } from '@/components/LandingMozza/common/Faq/types';

import useStyles from './styles';

const QuestionAndAnswer: FC<QuestionAndAnswerType> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes, cx } = useStyles({ isOpen });

  return (
    <div
      onClick={() => setIsOpen(!isOpen)}
      onKeyDown={() => setIsOpen(!isOpen)}
      role="button"
      tabIndex={0}
      className={classes.questionContainer}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <div className={classes.questionRow}>
        <Typography variant="bodyLgBold" component="h3" itemProp="name">
          {question}
        </Typography>
        <ExpandMoreIcon
          onClick={() => setIsOpen(!isOpen)}
          fontSize="small"
          className={classes.icon}
        />
      </div>
      <div
        className={cx(classes.answer, isOpen && classes.showAnswer)}
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <Typography variant="bodyMd" component="p" itemProp="text">
          {answer}
        </Typography>
      </div>
    </div>
  );
};

export default QuestionAndAnswer;
