import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isOpen: boolean }>()(
  ({ shadow, breakpoints, palette: { common } }, { isOpen }) => ({
    questionContainer: {
      backgroundColor: common.white,
      borderRadius: rem(12),
      width: '100%',
      cursor: 'pointer',
      overflow: 'hidden',
      padding: rem(12),
      gap: rem(16),
      boxShadow: shadow.low,
      [breakpoints.up('md')]: {
        padding: rem(24),
      },
    },
    questionRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
    icon: {
      cursor: 'pointer',
      transform: isOpen ? 'rotate(180deg)' : 'none',
    },
    answer: {
      overflow: 'hidden',
      height: isOpen ? 'auto' : 0,
      marginTop: isOpen ? rem(16) : 0,
      textAlign: 'justify',
    },
    showAnswer: {
      height: 'auto',
    },
    answerContent: {
      padding: rem(16),
      borderRadius: rem(12),
      display: 'block',
    },
  })
);
