import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isOnOneColumn }>()(
  ({ breakpoints }, { isOnOneColumn }) => ({
    ul: {
      columnCount: 2,
      columnGap: rem(40),
      [breakpoints.up('md')]: {
        columnCount: isOnOneColumn ? 1 : 4,
      },
    },
    li: {
      listStyleType: 'none',
      paddingBottom: rem(12),
      [breakpoints.up('md')]: {
        paddingBottom: rem(20),
      },
    },
  })
);
