import { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import QuestionAndAnswer from '@/components/LandingMozza/common/Faq/QuestionAndAnswer/QuestionAndAnswer';
import { QuestionAndAnswerType } from '@/components/LandingMozza/common/Faq/types';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';
import Button from '@/designSystem/Button/Button';

import messages from './messages';

import useStyles from './styles';

type Props = {
  title?: string | ReactElement;
  questionsAndAnswers: QuestionAndAnswerType[];
  handleRedirection?: () => void;
};

const Faq: FC<Props> = ({ title, handleRedirection, questionsAndAnswers }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.faq}>
      <ArticleHeader>
        <SectionTitle>
          {title ?? (
            <FormattedMessage
              {...messages.title}
              values={{
                enter: EnterChunk,
                regular: (chunks) => (
                  <span className={classes.title}>{chunks}</span>
                ),
              }}
            />
          )}
        </SectionTitle>
      </ArticleHeader>

      <div className={classes.faqQuestionsAndAnswersList}>
        {questionsAndAnswers.map(
          (questionAndAnswer: QuestionAndAnswerType, index) => (
            <QuestionAndAnswer
              key={`key-FAQ-${index}`}
              question={questionAndAnswer.question}
              answer={questionAndAnswer.answer}
            />
          )
        )}
      </div>
      {handleRedirection && (
        <Button
          variant="primary"
          size="large"
          onClick={handleRedirection}
          label={formatMessage(messages.findTeacherButton)}
        />
      )}
    </div>
  );
};

export default Faq;
