import { FC } from 'react';

const SquareBlueCotillon: FC = () => (
  <svg
    width="102"
    height="125"
    viewBox="0 0 102 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M72.2045 111.903C69.9545 110.343 68.4644 108.773 66.6644 108.183C62.0744 106.683 62.0443 106.953 56.5844 108.783C41.7743 113.783 26.9444 118.593 12.1144 123.473C9.95438 124.183 7.85434 124.673 5.82434 122.793C4.82434 121.883 3.33427 121.543 1.75427 120.793L4.94446 117.883L0.944458 118.703C1.14323 117.941 1.45316 117.214 1.86438 116.543C2.38133 115.897 3.07683 115.417 3.86438 115.163C7.72438 114.313 8.53447 111.413 9.45447 108.073C15.4545 86.3028 21.6944 64.5828 27.8644 42.8428C28.8644 39.5328 29.8644 36.2528 30.8644 32.9528C31.4744 30.9528 32.6944 29.6228 34.8644 29.5328C35.3944 29.436 35.9417 29.5242 36.4144 29.7828C38.7744 31.9528 40.9744 34.3129 43.4144 36.3729C43.8842 36.7085 44.4166 36.9461 44.9801 37.0715C45.5436 37.1969 46.1267 37.2076 46.6945 37.1028C56.9845 34.7228 64.8744 28.6729 71.1244 20.3929C74.4644 15.9729 77.4544 11.3028 80.6744 6.79279C82.4944 4.23279 84.6744 2.04278 87.8644 1.28278C90.2544 0.722779 92.2344 0.762794 93.6044 3.42279C94.3744 4.91279 96.0645 5.90286 97.1945 7.24286C102.294 13.3429 101.534 20.1829 99.5043 27.1229C97.9243 32.4729 96.1844 37.7629 94.5944 43.1229C90.9077 55.5362 87.241 67.9595 83.5944 80.3929C82.6844 83.5229 81.9145 86.7029 81.2345 89.8929C80.9737 90.9375 81.0222 92.0353 81.3744 93.0528C82.3744 95.3928 81.1443 96.9828 80.0543 98.7828C78.7688 100.9 77.6068 103.091 76.5743 105.343C75.4643 107.973 75.2045 111.273 72.2045 111.903ZM74.2943 45.0528C71.4043 46.5328 68.9744 47.9328 66.4244 49.0528C58.7544 52.3928 51.0544 54.3329 43.3344 49.1129C41.8344 48.1129 41.2343 49.2228 40.7943 50.4528C38.7143 56.3428 36.4044 62.1529 34.5944 68.1229C31.3044 78.9329 28.2745 89.8128 25.2045 100.693C24.6745 102.553 24.4743 104.503 24.0043 107.013L29.5944 105.323C40.0244 102.183 50.4644 99.0928 60.8644 95.8528C61.8644 95.5528 63.1545 94.4028 63.2245 93.5628C64.2245 81.9128 67.1144 70.6628 70.1444 59.4428C71.4044 54.7328 72.8343 50.0828 74.2943 45.0528ZM55.6244 38.4428C58.7744 38.6628 63.4245 35.8929 65.4945 32.6329L55.6244 38.4428Z"
      fill="#4E80EF"
    />
  </svg>
);

export default SquareBlueCotillon;
