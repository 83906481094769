import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';

import messages from './messages';

import useStyles from './styles';

const SeoHeader: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <SectionTitle className={classes.title}>
        <FormattedMessage
          {...messages.title}
          values={{
            break: (chunks) => <span>{chunks}</span>,
          }}
        />
      </SectionTitle>
      <Typography
        component="p"
        variant="bodyXl"
        className={classes.description}
      >
        <FormattedMessage
          {...messages.description}
          values={{
            break: (chunks) => <span>{chunks}</span>,
          }}
        />
      </Typography>
    </div>
  );
};

export default SeoHeader;
