import { FC } from 'react';
import { SeoContentLink } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import useStyles from '@/components/LandingMozza/common/SeoTable/styles';
import SeoContainerLabel from '@Landing/Systems/SeoContainer/SeoContainerLabel/SeoContainerLabel';

type Props = {
  links: SeoContentLink[];
  title: string;
  type: 'subject' | 'location' | 'level';
  isOneColumn?: boolean;
};

const SeoTable: FC<Props> = ({
  links,
  title,
  type,
  isOneColumn: isOnOneColumn = false,
}) => {
  const { classes, cx } = useStyles({ isOnOneColumn });

  return (
    <div className={classes.container}>
      <Typography
        variant="titleSm"
        component="h3"
        className={cx(classes.title, classes.categoryTitle)}
      >
        {title}
      </Typography>
      <SeoContainerLabel
        className={classes.links}
        links={links}
        type={type}
        isOnOneColumn={isOnOneColumn}
      />
    </div>
  );
};

export default SeoTable;
