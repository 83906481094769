import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isOnOneColumn }>()(
  ({ palette, border, breakpoints }, { isOnOneColumn }) => ({
    container: {
      display: 'flex',
      backgroundColor: palette.common.white,
      border: border.main,
      borderRadius: rem(16),
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: rem(16),
      padding: `${rem(24)} ${rem(16)}`,
      [breakpoints.up('md')]: {
        gap: rem(52),
        padding: `${rem(44)} ${rem(60)} ${rem(60)} ${rem(60)}`,
      },
      flex: 1,
    },
    title: {
      width: '100%',
      textAlign: isOnOneColumn ? 'left' : 'center',
    },
    categoryTitle: {
      fontSize: rem(20),
      fontWeight: 500,
      [breakpoints.up('md')]: {
        fontSize: rem(32),
      },
    },
    links: {
      display: isOnOneColumn ? 'flex' : 'block',
      alignItems: isOnOneColumn ? 'left' : 'center',
      flexDirection: 'column',
      width: '100%',
      textAlign: 'left',
      padding: 0,
    },
  })
);
